@import "../../../styles/config.scss";

.pagination {
  align-items: center;
  border-top: 1px solid getColor($softGray, 300);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-top: pxToRem(32px);
  padding: pxToRem(32px) pxToRem(16px) pxToRem(16px);

  @media (min-width: getFromMap($mediaQueries, "tablet")) {
    flex-direction: row;
  }

  &_text {
    color: getColor($neutral, 800);
  }

  &_element {
    justify-content: center;
    order: -1;
    margin-bottom: pxToRem(12px);

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      order: initial;
    }
  }
}
