@import "../../styles/config.scss";

.recommendations {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  width: 100vw;

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    animation-name: slide-in;
    max-width: pxToRem(420px);
    transition: max-width 0.25s ease-in-out;
  }

  &.maximized {
    max-width: 100vw;
  }

  &_loader {
    background-color: getColor($neutral, 0);
  }
}

.header,
.tabs_inner {
  max-width: pxToRem(824px);
  margin: 0 auto;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: pxToRem(16px) pxToRem(16px) pxToRem(8px) pxToRem(16px);

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: pxToRem(24px) pxToRem(24px) pxToRem(8px) pxToRem(24px);
  }
}

.actions {
  display: flex;
  position: absolute;
  right: pxToRem(16px);
  top: pxToRem(8px);

  &_maximize {
    display: none;

    @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
      display: initial;
    }
  }

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    right: pxToRem(24px);
    top: pxToRem(16px);
  }
}

.tabs {
  border-top: 1px solid getColor($softGray, 200);
  display: flex;
  justify-content: center;

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    border-top: none;
    border-bottom: 1px solid getColor($softGray, 200);
  }

  &_inner {
    padding: 0 pxToRem(16px);
    width: 100%;

    @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
      padding: 0 pxToRem(24px);
    }
  }

  &_splitter {
    height: pxToRem(16px);
    margin: 0 pxToRem(8px);
    width: pxToRem(16px);
  }

  &_list {
    align-items: center;
    display: flex;
  }

  &_button {
    align-items: center;
    background: none;
    border: none;
    border-top: 2px solid transparent;
    color: getColor($neutral, 600);
    cursor: pointer;
    display: flex;
    flex: 0 1 auto;
    outline: none;
    overflow: hidden;
    position: relative;
    padding: pxToRem(16px) 0;
    text-decoration: none;

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      border-bottom: 2px solid transparent;
      min-height: pxToRem(40px);
      padding: pxToRem(8px) 0;
    }

    & + & {
      margin-left: pxToRem(12px);
    }

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.active {
      border-top-color: getColor($purple, 500);
      color: getColor($purple, 500);
      cursor: initial;
      flex-shrink: 0;

      @media (min-width: getFromMap($mediaQueries, "tablet")) {
        border-bottom-color: getColor($purple, 500);
        border-top: none;
      }
    }

    &:hover:not(.active) {
      border-bottom-color: getColor($purple, 100);
      color: getColor($purple, 500);
    }

    & svg {
      height: pxToRem(16px);
      margin-right: pxToRem(8px);
      width: pxToRem(16px);
    }
  }

  &_spinner {
    position: absolute;
    right: 0;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(400px);
  }

  to {
    transform: translateY(0);
  }
}

.disclaimer {
  animation-name: slide-in;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  overflow: hidden;
  position: relative;
  padding: pxToRem(16px);
  width: 100%;

  &_close {
    border-radius: pxToRem(4px);
    position: absolute;
    right: pxToRem(24px);
    top: pxToRem(24px);
  }

  &_modal {
    z-index: 5000;
  }

  &_header svg,
  &_title {
    color: getColor($teal, 500);
  }

  &_body {
    background-color: getColor($neutral, 0);
    border-radius: pxToRem(8px);
    padding: pxToRem(16px);
  }

  &_header {
    display: flex;
    flex-direction: column;
  }

  &_title {
    padding-bottom: pxToRem(8px);
    padding-top: pxToRem(4px);
  }

  &_text {
    color: getColor($neutral, 800);
  }
}
