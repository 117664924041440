@import "../../../styles/config.scss";

.tasks {
  position: absolute;
  right: pxToRem(16px);
  top: pxToRem(16px);

  &_wrapper {
    display: flex;
    padding: 0;
  }
}
