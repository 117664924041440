@import "../../../styles/config.scss";

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 pxToRem(16px) pxToRem(24px);
  width: 100%;

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    align-items: center;
    flex-direction: row;
    padding: 0 0 pxToRem(24px) 0;
  }

  &_add_client {
    flex-shrink: 0;
    min-width: pxToRem(120px);

    &:hover {
      background-color: transparent;
    }

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      min-width: initial;
    }
  }

  &_bottom {
    padding-top: pxToRem(32px);
  }

  &_group {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: pxToRem(16px);

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      padding-top: 0;
    }
  }

  &_title {
    color: getColor($neutral, 800);
    margin-right: pxToRem(32px);
  }

  &_search {
    align-items: center;
    display: flex;
    margin-left: pxToRem(12px);
    overflow: hidden;
    width: 100%;
  }

  &_search_toggle {
    display: none;

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      display: initial;
      flex-shrink: 0;
    }
  }

  &_search &_search_toggle {
    &:hover,
    &:focus {
      background-color: transparent;
      color: getColor($red, 500);
    }
  }

  &_search &_search_toggle > svg {
    height: pxToRem(16px);
    width: pxToRem(16px);
  }

  &_input {
    background: none;
    border: none;
    border-bottom: 1px solid getColor($softGray, 300);
    min-width: pxToRem(320px);
    width: 100%;

    &_icon {
      height: pxToRem(16px);
      fill: getColor($neutral, 600);
      width: pxToRem(16px);
    }

    &_container {
      overflow: hidden;
      transition: max-width $hoverTransition;
      width: 100%;

      @media (min-width: getFromMap($mediaQueries, "tablet")) {
        max-width: 0;

        &.active {
          max-width: pxToRem(480px);
        }
      }
    }
  }
}
