@import "../../../styles/config.scss";

.graph {
  display: none;
  height: 100%;
  position: relative;
  width: 100%;

  @media (min-width: getFromMap($mediaQueries, "tablet")) {
    display: block;
  }

  &_label {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 0;
  }

  &_wrapper {
    height: 100%;
    max-height: 100vh;
    min-height: calc(100vh - 2rem);
    min-width: pxToRem(1080px);
    padding: pxToRem(32px) pxToRem(96px);
    width: 100%;

    @media screen and (min-width: getFromMap($mediaQueries, "laptop")) {
      min-height: pxToRem(640px);
      padding: pxToRem(64px) 0 pxToRem(48px);
    }

    @media screen and (min-width: getFromMap($mediaQueries, "desktop")) {
      max-height: 100%;
    }
  }
}
