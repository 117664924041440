@import "../../styles/config.scss";

.error {
  align-items: center;
  background-color: #f1f1f4;
  background-image: url("../../assets/SS_Background.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100vh;
  min-height: 100vh;

  &_wrapper {
    align-items: center;
    flex-direction: column;
    display: flex;
  }

  &_text {
    align-items: center;
    padding-bottom: pxToRem(24px);
    display: flex;
  }

  &_code {
    border-right: 1px solid getColor($softGray, 500);
    margin-right: pxToRem(16px);
    padding: pxToRem(8px) pxToRem(16px) pxToRem(8px) 0;
  }
}
