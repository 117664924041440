@import "../../../styles/config.scss";

.grid {
  display: grid;
  gap: pxToRem(16px);
  padding: 0 pxToRem(16px);

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    grid-template-columns: repeat(2, 1fr);
    gap: pxToRem(24px);
    padding: 0;
  }
}
