@import "../../styles/config.scss";

.score {
  animation-duration: 0.2s;
  animation-name: animate-in;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  box-shadow: getFromMap($shadow, "xxl");
  margin-top: pxToRem(12px);
  padding: 0;
  transform-origin: top right;
  width: pxToRem(280px);

  &_row {
    border-bottom: 1px solid getColor($softGray, 100);
    display: flex;
    flex-direction: column;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    &_title {
      align-items: center;
      display: flex;
      color: getColor($neutral, 800);
    }
  }

  &_scores {
    color: getColor($neutral, 800);
    display: flex;
    justify-content: space-between;
    margin-top: pxToRem(8px);

    &_column {
      display: flex;
      justify-content: space-between;
      flex: 1;

      & + & {
        margin-left: pxToRem(16px);
      }
    }

    .negative {
      color: getColor($red, 500);
    }

    .positive {
      color: getColor($green, 500);
    }
  }
}