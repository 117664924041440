@import "../../../styles/config.scss";

.input {
  @extend .bodyTwo;
  background: getColor($neutral, 0);
  border: 1px solid getColor($neutral, 200);
  color: getColor($neutral, 1000);
  transition: $hoverTransition;
  width: 100%;

  &::placeholder {
    color: getColor($neutral, 400);
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: getColor($purple, 500);
  }

  &:hover[aria-invalid="true"] {
    border-color: getColor($red, 500);
  }

  &:focus:not([aria-readonly="true"]) {
    @include focusDropshadow;
  }

  &[aria-invalid="true"] {
    border-color: getColor($red, 500);
  }

  &:focus[aria-invalid="true"] {
    @include errorFocusDropshadow;
    border-color: getColor($red, 500);
  }

  &[data-valid="true"] {
    background-color: getColor($green, 50);
    border-color: getColor($green, 500);
  }

  &:disabled {
    cursor: not-allowed;
    color: getColor($neutral, 600);
  }

  &:disabled:not([aria-readonly="true"]) {
    background-color: getColor($neutral, 50);
  }

  // Padding for when a `LeftInputElement` or `RightInputElement`
  // is beside the input.

  &.leftElement_xl {
    padding-left: pxToRem(72px);
  }

  &.leftElement_lg {
    padding-left: pxToRem(64px);
  }

  &.leftElement_md {
    padding-left: pxToRem(48px);
  }

  &.leftElement_sm {
    padding-left: pxToRem(32px);
  }

  &.leftElement_xs {
    padding-left: pxToRem(24px);
  }

  &.rightElement_xl {
    padding-right: pxToRem(72px);
  }

  &.rightElement_lg {
    padding-right: pxToRem(64px);
  }

  &.rightElement_md {
    padding-right: pxToRem(48px);
  }

  &.rightElement_sm {
    padding-right: pxToRem(32px);
  }

  &.rightElement_xs {
    padding-right: pxToRem(24px);
  }
}

.md {
  height: pxToRem(48px);
  padding: 0 pxToRem(16px);
}

.sm {
  height: pxToRem(40px);
  padding: 0 pxToRem(16px);
}

.xs {
  @extend .caption;
  height: pxToRem(32px);
  padding: 0 pxToRem(12px);
}
