@import "../../../../styles/config.scss";

@keyframes animate-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.drilldown {
  animation-duration: 0.2s;
  animation-name: animate-in;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  background-color: getColor($neutral, 0);
  border-radius: pxToRem(4px);
  box-shadow: getFromMap($shadow, "md");
  margin-left: pxToRem(12px);
  max-width: pxToRem(320px);
  overflow: hidden;
  padding: 0;
  transform-origin: left;
  width: calc(100% - 0.75rem);
  z-index: -1;

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    width: 100%;
  }

  &_title {
    align-items: center;
    color: getColor($neutral, 800);
    display: flex;
    justify-content: space-between;
    padding: pxToRem(12px) pxToRem(16px) 0;

    @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
      padding: pxToRem(12px) pxToRem(16px) 0;
    }
  }

  &_list {
    background-color: getColor($neutral, 0);
    display: flex;
    flex-direction: column;

    &_title {
      color: getColor($neutral, 800);
    }
  }

  &_sub_dimension {
    display: flex;
    flex-direction: column;
    padding: pxToRem(12px) pxToRem(16px) pxToRem(16px);
  }

  &_row {
    align-items: center;
    background: none;
    border: none;
    color: getColor($neutral, 400);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    outline: none;
    padding: pxToRem(12px) pxToRem(16px);
    transition: $hoverTransition;
    text-align: left;
    width: 100%;

    & + & {
      margin-top: pxToRem(8px);
    }

    @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
      border-radius: pxToRem(4px);
      padding: pxToRem(12px) pxToRem(16px);
    }

    &_icon {
      align-items: center;
      background-color: transparent;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      height: pxToRem(16px);
      flex-shrink: 0;
      width: pxToRem(16px);
      margin-left: pxToRem(12px);

      &_empty {
        border: 1px solid currentColor;
        border-radius: 24px;
        height: 100%;
        width: 100%;
      }

      &_active {
        background-color: currentColor;
        border-color: transparent;
      }

      &_check {
        fill: getColor($neutral, 0);
        height: pxToRem(8px);
        width: pxToRem(8px);
      }

      &_add {
        height: pxToRem(16px);
        width: pxToRem(16px);
      }
    }

    &_teal {
      &.drilldown_active {
        background-color: getColor($teal, 50);
        color: getColor($teal, 500);
      }

      &:hover {
        background-color: getColor($teal, 50);
        color: getColor($teal, 500);
      }
    }

    &_orange {
      &.drilldown_active {
        background-color: getColor($orange, 50);
        color: getColor($orange, 500);
      }

      &:hover {
        background-color: getColor($orange, 50);
        color: getColor($orange, 500);
      }
    }

    &_purple {
      &.drilldown_active {
        background-color: getColor($purple, 50);
        color: getColor($purple, 500);
      }

      &:hover {
        background-color: getColor($purple, 50);
        color: getColor($purple, 500);
      }
    }

    &_red {
      &.drilldown_active {
        background-color: getColor($red, 50);
        color: getColor($red, 500);
      }

      &:hover {
        background-color: getColor($red, 50);
        color: getColor($red, 500);
      }
    }

    &_softGray {
      &.drilldown_active {
        background-color: getColor($softGray, 50);
        color: hsla(32, 13%, 45%, 1);
      }

      &:hover {
        background-color: getColor($softGray, 50);
        color: hsla(32, 13%, 45%, 1);
      }
    }

    &_green {
      &.drilldown_active {
        background-color: hsl(82, 67%, 95%);
        color: hsl(82, 67%, 40%);
      }

      &:hover {
        background-color: hsl(82, 67%, 95%);
        color: hsl(82, 67%, 40%);
      }
    }
  }
}
