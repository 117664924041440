// regular
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/NotoSansJP-Regular.otf");
}

// medium
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts//NotoSansJP-Medium.otf");
}

// bold
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts//NotoSansJP-Bold.otf");
}
