@import "../../../styles/config.scss";

.link {
  color: getColor($purple, 500);
  text-decoration: none;

  &:hover {
    color: getColor($red, 500);
  }

  &:disabled {
    color: getColor($neutral, 400);
  }

  &:focus {
    @include focusDropshadow;
  }

  // This will disable the focus ring when clicked where it's supported.
  &:focus:not(:focus-visible) {
    box-shadow: none;
    z-index: 0;
  }

  & svg {
    height: pxToRem(16px);
    margin-left: 4px;
    vertical-align: middle;
    width: pxToRem(16px);
  }

  &.lg {
    @extend .bodyTwo;
  }

  &.md {
    @extend .caption;

    & svg {
      height: 12px;
      width: 12px;
    }
  }

  &.sm {
    font-size: pxToRem(12px);
    line-height: pxToRem(16px);

    & svg {
      height: 8px;
      width: 8px;
    }
  }

  &.disabled {
    color: getColor($neutral, 400);
    cursor: not-allowed;
    text-decoration: none;
  }
}
