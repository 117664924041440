@import "../../../styles/config.scss";

.score {
  background-color: getColor($neutral, 50);
  color: getColor($purple, 900);
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: pxToRem(16px);

  &_rank {
    margin-right: pxToRem(8px);
  }
}