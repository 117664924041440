@import "../../../../styles/config.scss";

.scores {
  display: flex;
  flex-direction: column;

  &_rows {
    @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
      max-height: calc(100vh - 12rem);
      overflow-y: auto;
      scrollbar-width: thin;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: getColor($neutral, 0);
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: getColor($purple, 500);
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: getColor($purple, 600);
      }
    }

    @media screen and (min-width: getFromMap($mediaQueries, "desktop")) {
      max-height: initial;
    }
  }

  &_row {
    padding: pxToRem(16px);
  }
}
