@import "../../../styles/config.scss";

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: getFromMap($mediaQueries, "tablet")) {
    padding: 0 pxToRem(16px);
  }

  @media (min-width: getFromMap($mediaQueries, "laptop")) {
    padding: 0;
  }
}

.form {
  height: 100%;
  &_fields {
    display: flex;
    flex-direction: column;

    & > div + div {
      margin-top: pxToRem(16px);
    }

    & input[aria-readonly="true"] {
      background-color: getColor($neutral, 100);
    }

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      flex-direction: row;
      & > div + div {
        margin-top: 0;
        margin-left: pxToRem(16px);
      }
    }
  }

  &_alert {
    margin-top: pxToRem(24px);
  }

  &_submitted {
    align-items: center;
    background-color: getColor($neutral, 0);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: pxToRem(480px);
    padding: pxToRem(24px);
    pointer-events: initial;

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      padding: pxToRem(32px);
    }

    &_title {
      color: getColor($neutral, 800);
      margin-bottom: pxToRem(16px);
      text-align: center;
    }
  }

  &_error {
    color: getColor($red, 500);
    margin-top: pxToRem(16px);
  }
}
