@import "../../../styles/config.scss";

@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spinner 0.45s linear 0s infinite normal none running;
  border-top: 2px solid currentcolor;
  border-right: 2px solid currentcolor;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99999px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  display: inline-block;

  &.xxs {
    height: pxToRem(12px);
    width: pxToRem(12px);
  }

  &.xs {
    height: pxToRem(16px);
    width: pxToRem(16px);
  }

  &.sm {
    height: pxToRem(24px);
    width: pxToRem(24px);
  }

  &.md {
    height: pxToRem(32px);
    width: pxToRem(32px);
  }

  &.lg {
    height: pxToRem(40px);
    width: pxToRem(40px);
  }

  &.xl {
    height: pxToRem(48px);
    width: pxToRem(48px);
  }

  &.purple {
    color: getColor($purple, 500);
  }

  &.currentColor {
    color: currentColor;
  }
}
