@import "../../../styles/config.scss";

.preventScroll {
  max-height: 100vh;
  overflow: hidden;
}

.modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}
