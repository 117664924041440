@import "../../../styles/config.scss";

.button {
  @extend .buttonText;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  align-items: center;
  background: none;
  border: 1px solid transparent;
  border-radius: 0;
  display: inline-flex;
  justify-content: center;
  outline: none;
  text-decoration: none;
  transition: all $hoverTransition;

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &:focus {
    @include focusDropshadow;
  }
  // This will disable the focus ring when clicked where it's supported.
  &:focus:not(:focus-visible) {
    box-shadow: none;
    z-index: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &[data-loading] {
    opacity: 0.4;
  }

  &.lg {
    height: pxToRem(48px);
    padding-left: pxToRem(24px);
    padding-right: pxToRem(24px);
  }

  &.md {
    height: pxToRem(40px);
    padding-left: pxToRem(16px);
    padding-right: pxToRem(16px);
  }

  &.sm {
    @extend .buttonText--small;
    height: pxToRem(32px);
    padding-left: pxToRem(12px);
    padding-right: pxToRem(12px);
  }

  & svg {
    height: pxToRem(16px);
    width: pxToRem(16px);
  }
}

.filled {
  background-color: getColor($purple, 500);
  color: getColor($neutral, 0);

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    border-color: getColor($red, 500);
    color: getColor($red, 500);
  }

  &:hover:not(:disabled) {
    background-color: transparent;
  }

  &:active:not(:disabled) {
    background-color: getColor($red, 50);
  }

  &:disabled:not([data-loading]) {
    background-color: getColor($neutral, 200);
    color: getColor($neutral, 400);
  }
}

.outline {
  background-color: transparent;
  border-color: getColor($purple, 500);
  color: getColor($purple, 500);

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    border-color: getColor($red, 500);
    color: getColor($neutral, 0);
  }

  &:hover:not(:disabled) {
    background-color: getColor($red, 500);
  }

  &:active:not(:disabled) {
    background-color: getColor($red, 600);
  }

  &:disabled:not([data-loading]) {
    border-color: getColor($neutral, 600);
    color: getColor($neutral, 400);
  }
}

.white {
  background-color: getColor($neutral, 0);
  border-color: getColor($neutral, 0);
  color: getColor($purple, 500);

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    color: getColor($neutral, 0);
  }

  &:hover:not(:disabled) {
    background-color: transparent;
  }

  &:active:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:disabled:not([data-loading]) {
    background-color: getColor($neutral, 0);
    color: getColor($neutral, 800);
    opacity: 0.5;
  }
}

.ghost {
  background-color: transparent;
  border-color: transparent;
  color: getColor($purple, 500);

  &:hover,
  &:active {
    background-color: getColor($red, 50);
    color: getColor($red, 500);
  }

  &:active {
    background-color: getColor($red, 100);
  }

  &:disabled:not([data-loading]) {
    color: getColor($neutral, 400);
  }
}

.iconLeft {
  margin-right: pxToRem(8px);
}

.iconRight {
  margin-left: pxToRem(8px);
}

.loading {
  display: inline-block;
  margin-left: pxToRem(8px);
}

.hiddenLoading {
  display: none;
  opacity: 0;
}
