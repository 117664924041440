body {
  margin: 0;
  font-family: "GT-Walsheim Pro", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*:focus {
  outline: none;
}

// Reset default border-radius set on Edge browsers
* {
  border-radius: 0;
}
