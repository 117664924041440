@import "../../styles/config.scss";

.pagination {
  display: flex;
}

.pagination_button {
  align-items: center;
  background: none;
  border: none;
  border-radius: 50px;
  display: flex;
  color: getColor($purple, 500);
  cursor: pointer;
  height: pxToRem(40px);
  outline: none;
  justify-content: center;
  width: pxToRem(40px);

  @media (min-width: getFromMap($mediaQueries, "tablet")) {
    height: pxToRem(32px);
    width: pxToRem(32px);
  }

  &:not(.active):not(.direction) {
    display: none;

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      display: initial;
    }
  }

  & + & {
    margin-left: pxToRem(8px);
  }

  &:hover:not(.disabled):not(.active) {
    background-color: getColor($purple, 100);
    color: getColor($purple, 500);
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.active {
    background-color: getColor($purple, 500);
    color: getColor($neutral, 0);
  }
}

.pagination_icon {
  height: pxToRem(16px);
  width: pxToRem(16px);
}
