@import "../../../styles/config.scss";

.navigation {
  align-items: center;
  display: inline-flex;
  left: pxToRem(16px);
  margin-right: pxToRem(12px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 1.5rem);

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    margin-right: 0;
  }
}
