@import "../../../styles/config.scss";

.graph {
  align-items: center;
  display: none;
  flex-direction: column;
  height: 100%;
  width: 100%;
  pointer-events: none;
  scrollbar-width: thin;
  width: 100%;

  @media (min-width: getFromMap($mediaQueries, "tablet")) {
    display: flex;
  }

  &_wrapper {
    height: 100%;
    max-height: 100vh;
    min-height: calc(100vh - 2rem);
    width: 100%;

    @media screen and (min-width: getFromMap($mediaQueries, "laptop")) {
      padding: 0;
    }

    @media screen and (min-width: getFromMap($mediaQueries, "desktop")) {
      max-height: pxToRem(720px);
      padding: 0;
    }
  }

  &_bars {
    display: grid;
    grid-template-columns: 1fr;
    gap: pxToRem(64px);
    min-width: pxToRem(1080px);
    padding: pxToRem(96px) pxToRem(96px) pxToRem(72px);

    @media screen and (min-width: getFromMap($mediaQueries, "desktop")) {
      padding: pxToRem(120px) pxToRem(120px) pxToRem(64px);
    }
  }
}

.bar {
  max-height: pxToRem(200px);
  width: 100%;

  &_question {
    margin-bottom: pxToRem(24px);
    &_id {
      color: getColor($neutral, 600);
      display: block;
      margin-bottom: pxToRem(4px);
    }

    &_title {
      color: getColor($purple, 800);
      padding-right: pxToRem(72px);
    }
  }

  &_chart {
    max-height: pxToRem(240px);
  }
}
