@import "../../../styles/config.scss";

.button {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  color: getColor($neutral, 400);
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  max-height: pxToRem(48px);
  min-height: pxToRem(48px);
  min-width: pxToRem(48px);
  position: relative;
  z-index: 1;

  &:disabled {
    pointer-events: none;
  }

  &_wrapper {
    align-items: center;
    border-radius: pxToRem(4px);
    display: flex;
    justify-content: center;
    height: pxToRem(40px);
    width: pxToRem(40px);
  }

  &:hover svg,
  &:focus svg {
    color: getColor($purple, 900);
  }

  &_active &_wrapper {
    background-color: getColor($purple, 800);

    & svg {
      color: getColor($neutral, 0);
    }

    &:hover svg {
      color: getColor($neutral, 0);
    }
  }

  & svg {
    height: pxToRem(20px);
    width: pxToRem(20px);
  }
}

.popper {
  background-color: getColor($purple, 900);
  border-radius: pxToRem(4px);
  display: flex;
  white-space: nowrap;
  color: getColor($neutral, 0);
  padding: pxToRem(8px) pxToRem(12px);
  z-index: 1000;
}

.badge {
  align-items: center;
  background-color: getColor($purple, 500);
  border-radius: pxToRem(24px);
  color: getColor($neutral, 0);
  display: flex;
  height: pxToRem(20px);
  justify-content: center;
  position: absolute;
  right: pxToRem(-8px);
  top: pxToRem(-8px);
  width: pxToRem(20px);
  z-index: 100;
}
