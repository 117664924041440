@import "../../styles/config.scss";

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  pointer-events: initial;
  width: 100%;

  @media (min-width: getFromMap($mediaQueries, "tablet")) {
    max-width: pxToRem(720px);
    height: initial;
  }

  & fieldset {
    border: none;
    padding: 0;
  }

  &_body {
    background-color: getColor($neutral, 0);
    height: 100%;
    overflow-y: auto;
    padding: pxToRem(32px) pxToRem(24px);

    @media (min-width: getFromMap($mediaQueries, "laptop")) {
      padding: pxToRem(32px);
    }
  }

  &_header {
    align-items: center;
    display: flex;
    flex-direction: column;

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      flex-direction: row;
    }
  }

  &_icon {
    align-items: center;
    background-color: getColor($purple, 100);
    border-radius: 24px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: pxToRem(32px);
    margin-bottom: pxToRem(12px);
    width: pxToRem(32px);

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      margin-bottom: 0;
      margin-right: pxToRem(12px);
    }

    & svg {
      height: pxToRem(16px);
      width: pxToRem(16px);
    }
  }

  &_title {
    text-align: center;
  }

  &_title,
  &_icon {
    color: getColor($purple, 500);
  }

  &_fields {
    margin-top: pxToRem(24px);

    & > fieldset + fieldset {
      margin-top: pxToRem(16px);
    }
  }

  &_footer {
    background-color: getColor($softGray, 100);
    border-top: 1px solid getColor($softGray, 300);
    padding: pxToRem(24px);
    width: 100%;

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      padding: pxToRem(16px) pxToRem(32px);
    }

    &_group {
      flex-direction: column;

      & > button:first-child {
        margin-bottom: pxToRem(12px);
      }

      & > button:last-child {
        &:hover {
          background-color: transparent;
        }
      }

      @media (min-width: getFromMap($mediaQueries, "tablet")) {
        flex-direction: row;

        & > button:first-child {
          margin-bottom: 0;
          order: 1;
        }
      }
    }
  }
}
