@import "../../styles/config.scss";

.nav {
  background-color: getColor($purple, 900);
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  padding: pxToRem(12px) pxToRem(16px);

  &_sign_out {
    margin-left: auto;
    margin-right: pxToRem(24px);
  }

  &_container {
    align-items: center;
    display: flex;
    margin-right: auto;
    overflow: hidden;
  }

  &_button {
    color: getColor($neutral, 0);

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: getColor($neutral, 0);
    }
  }

  &_wrapper {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;

    @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
      align-items: center;
      flex-direction: row;
    }
  }
}

.logo_container {
  align-items: center;
  display: flex;
  flex-shrink: 0;

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    margin-right: pxToRem(24px);
  }
}

.client_title {
  text-align: center;
  margin-left: pxToRem(12px);
  overflow: hidden;
  padding-right: pxToRem(12px);
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    border-left: 1px solid getColor($neutral, 0);
    display: initial;
    margin-left: 0;
    padding-left: pxToRem(24px);
  }
}

.client_title,
.logo_title {
  color: getColor($neutral, 0);
}

.logo_title {
  display: none;
  margin-left: pxToRem(12px);

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    display: initial;
  }
}

.logo {
  height: pxToRem(32px);
  width: pxToRem(32px);
}

.nav_link {
  align-items: center;
  color: getColor($neutral, 0);
  display: flex;
  margin-right: pxToRem(12px);
  padding: pxToRem(4px) pxToRem(12px);
  transition: opacity $hoverTransition;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: getColor($neutral, 0);
    opacity: 1;
  }
}

.sign_out {
  color: getColor($neutral, 0);
}
