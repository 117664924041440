// Media query values
$mediaQueries: (
  'phone': 375px,
  'tablet': 768px,
  'laptop': 1024px,
  'desktop': 1281px
);

$maxWidth: 1172px;

// shadows
$shadow: (
  'sm': 0px 1px 2px rgba(63, 61, 67, 0.08),
  'base': (0px 1px 2px rgba(63, 61, 67, 0.06), 0px 1px 3px rgba(63, 61, 67, 0.1)),
  'md': (0px 4px 12px -1px rgba(63, 61, 67, 0.08), 0px 8px 16px -1px rgba(63, 61, 67, 0.12)),
  'lg': (0px 4px 6px -2px rgba(63, 61, 67, 0.05), 0px 10px 16px -3px rgba(63, 61, 67, 0.1)),
  'xl': (0px 10px 10px -5px rgba(63, 61, 67, 0.04), 0px 20px 24px -5px rgba(63, 61, 67, 0.1)),
  'xxl': (0px 24px 48px -12px rgba(63, 61, 67, 0.24)),
  'shadow-outline': 0px 0px 0px 0.25rem rgba(79, 45, 127, 0.30),
  'error-shadow-outline': 0px 0px 0px 0.25rem rgba(222, 0, 45, 0.20),
  'focus': 0px 0px 0px 1px #4F2D7F
);

$letter-spacing: (
  'negative': (
    'large': -1px,
    'medium': -0.5px,
    'small': -0.25px,
    'extra-small': -0.1px,
  ),
  'positive': (
    'caps': 0.1em,
  )
);

// button
$hoverTransition: 0.15s ease-in-out;

// spacing
$spacing: (
  4: pxToRem(4px),
  8: pxToRem(8px),
  12: pxToRem(12px),
  16: pxToRem(16px),
  20: pxToRem(20px),
  24: pxToRem(24px),
  32: pxToRem(32px),
  40: pxToRem(40px),
  48: pxToRem(48px),
  56: pxToRem(56px),
  64: pxToRem(64px),
  72: pxToRem(72px),
  80: pxToRem(80px),
  96: pxToRem(96px),
  112: pxToRem(112px),
  144: pxToRem(144px)
);

$navHeight: (72px);