@import "../../styles/config.scss";

.wrapper {
  background-color: #f1f1f4;
  display: none;
  overflow-y: auto;
  scrollbar-width: thin;
  visibility: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: getColor($purple, 100);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: getColor($purple, 500);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &_inner {
    height: 100%;
    min-width: pxToRem(600px);
    min-height: pxToRem(600px);
    position: relative;

    @media screen and (min-width: getFromMap($mediaQueries, "laptop")) {
      &::before {
        background-color: #f1f1f4;
        content: "";
        display: block;
        position: absolute;
        right: 40px;
        top: 0;
        height: 410px;
        width: 310px;
      }
    }
  }
}

.report {
  display: flex;
  height: 100%;

  & iframe {
    border: none;
    pointer-events: none;
  }

  &.hidden {
    display: none;
  }
}
