@import "../../../styles/config.scss";

.crumbs {
  display: none;
  height: pxToRem(48px);

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    align-items: center;
    background-color: hsla(0, 0%, 100%, 0.9);
    box-shadow: getFromMap($shadow, "md");
    border-radius: pxToRem(4px);
    display: inline-flex;
    left: pxToRem(16px);
    min-height: pxToRem(40px);
    position: absolute;
    top: pxToRem(16px);
  }
}

.crumb {
  align-items: center;
  background: none;
  border: none;
  color: getColor($neutral, 800);
  display: flex;
  outline: none;
  transition: color $hoverTransition;
}

.separator {
  display: inline-block;
  height: pxToRem(16px);
  margin: 0 pxToRem(2px);
  width: pxToRem(16px);

  & svg {
    fill: getColor($neutral, 800);
  }
}
