@import "../../../styles/config.scss";

.error {
  align-items: center;
  background-color: #f1f1f4;
  background-image: url("../../../assets/SS_Background.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 100vh;
  padding: 0 pxToRem(16px);

  &_wrapper,
  &_details,
  &_group {
    display: flex;
    flex-direction: column;
  }

  &_group {
    align-items: center;
    margin-top: pxToRem(12px);
    text-align: center;
  }

  &_container {
    align-items: center;
    display: flex;
    flex-direction: column;

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      flex-direction: row;
    }
  }

  &_title,
  &_icon {
    color: getColor($purple, 800);
  }

  &_description {
    color: getColor($neutral, 800);
    margin-top: pxToRem(4px);
  }

  &_title,
  &_description {
    text-align: center;

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      text-align: left;
    }
  }

  &_details_centered > * {
    text-align: center;
  }

  &_icon {
    height: pxToRem(48px);
    margin-bottom: pxToRem(24px);
    width: pxToRem(48px);

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      margin-bottom: 0;
      margin-right: pxToRem(24px);
    }
  }

  &_return {
    margin-top: pxToRem(24px);
  }
}
