@import "../../../../styles/config.scss";

.card {
  box-shadow: getFromMap($shadow, "lg");
  grid-column: span 1;
  overflow: hidden;

  &_body {
    background-color: getColor($neutral, 0);
    padding: pxToRem(16px);

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      border-radius: pxToRem(4px) pxToRem(4px) 0 0;
      padding: pxToRem(24px);
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;
  }

  &_score {
    align-items: center;
    background-color: getColor($purple, 50);
    border-radius: 9999px;
    color: getColor($purple, 500);
    display: flex;
    height: pxToRem(48px);
    justify-content: center;
    margin-right: pxToRem(12px);
    width: pxToRem(48px);
  }

  &_client {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  &_overline {
    color: getColor($neutral, 600);
    font-weight: 300;
    margin-bottom: pxToRem(4px);
  }

  &_name {
    color: getColor($neutral, 800);
    margin-bottom: pxToRem(4px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_footer {
    background-color: #f9f9fb;
    border-radius: 0 0 pxToRem(2px) pxToRem(2px);
    border-top: 1px solid getColor($softGray, 100);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: pxToRem(16px);
    width: 100%;

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      align-items: center;
      padding: pxToRem(16px) pxToRem(24px);
    }

    &_rank {
      color: getColor($neutral, 800);
    }
  }

  &_footer &_button {
    @extend .caption;
    background: none;
    border: none;
    display: none;
    cursor: pointer;
    height: initial;
    outline: none;
    padding: 0;

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      display: block;
    }

    &_disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      background-color: transparent;
    }
  }
}
