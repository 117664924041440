@import "../../../styles/config.scss";

@keyframes animate-in {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.wrapper {
  background-color: getColor($neutral, 0);
  box-shadow: getFromMap($shadow, "md");
  border-radius: pxToRem(4px);
  min-width: pxToRem(48px);
  padding: pxToRem(4px) pxToRem(12px);
  pointer-events: initial;
  z-index: 1000;
}