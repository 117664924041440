@import "../../../styles/config.scss";

@keyframes slide-in {
  0% {
    transform: translateX(pxToRem(320px));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(pxToRem(320px));
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.drawer_body {
  animation-timing-function: ease-in-out;
  animation-duration: 0.25s;
  background-color: getColor($neutral, 0);
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-left: auto;
  max-width: pxToRem(320px);
  pointer-events: initial;
  width: 100%;

  &.open {
    animation-name: slide-in;
    animation-fill-mode: forwards;
  }

  &.closed {
    animation-name: slide-out;
    animation-fill-mode: backwards;
  }
}
