@import "../../../styles/config.scss";

.alert {
  align-items: flex-start;
  display: flex;
  max-width: pxToRem(558px);
  padding: pxToRem(12px);
  width: 100%;

  &.fullWidth {
    max-width: initial;
  }

  &.contentCentered {
    justify-content: center;
  }

  &.centered {
    margin: 0 auto;
  }

  &.textCentered {
    text-align: center;
  }

  // styles for error
  &.error {
    &.light {
      background-color: getColor($red, 50);

      & .title {
        color: getColor($red, 800);
      }

      & .description {
        color: getColor($red, 700);
      }

      & .icon {
        background-color: getColor($red, 100);
        color: getColor($red, 500);
      }
    }

    &.solid {
      background-color: getColor($red, 500);

      & .title,
      & .description {
        color: getColor($neutral, 0);
      }

      & .icon {
        background-color: getColor($red, 700);
        color: getColor($neutral, 0);
      }
    }
  }

  // styles for success
  &.success {
    &.light {
      background-color: getColor($green, 50);

      & .title {
        color: getColor($green, 800);
      }

      & .description {
        color: getColor($green, 700);
      }

      & .icon {
        background-color: getColor($green, 100);
        color: getColor($green, 500);
      }
    }

    &.solid {
      background-color: getColor($green, 500);

      & .title,
      & .description {
        color: getColor($neutral, 0);
      }

      & .icon {
        background-color: getColor($green, 700);
        color: getColor($neutral, 0);
      }
    }
  }

  // styles for warning
  &.warning {
    &.light {
      background-color: getColor($orange, 50);

      & .title {
        color: getColor($orange, 800);
      }

      & .description {
        color: getColor($orange, 700);
      }

      & .icon {
        background-color: getColor($orange, 100);
        color: getColor($orange, 800);
      }
    }

    &.solid {
      background-color: getColor($orange, 500);

      & .title,
      & .description {
        color: getColor($orange, 900);
      }

      & .icon {
        background-color: getColor($orange, 700);
        color: getColor($neutral, 0);
      }
    }
  }

  // styles for info
  &.info {
    &.light {
      background-color: getColor($teal, 50);

      & .title {
        color: getColor($teal, 800);
      }

      & .description {
        color: getColor($teal, 700);
      }

      & .icon {
        background-color: getColor($teal, 100);
        color: getColor($teal, 500);
      }
    }

    &.solid {
      background-color: getColor($teal, 500);

      & .title,
      & .description {
        color: getColor($neutral, 0);
      }

      & .icon {
        background-color: getColor($teal, 700);
        color: getColor($neutral, 0);
      }
    }
  }
}

.icon {
  align-items: center;
  border-radius: pxToRem(12px);
  display: flex;
  height: pxToRem(24px);
  justify-content: center;
  margin-right: pxToRem(8px);
  width: pxToRem(24px);

  & svg {
    height: pxToRem(12px);
    width: pxToRem(12px);
  }
}

.textContent {
  display: flex;
  flex-direction: column;
}

.title {
  @extend .bodyOne;
  font-weight: 700;
  padding-bottom: pxToRem(4px);
}

.description {
  @extend .bodyTwo;
}
