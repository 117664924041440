.heading-one,
.heading-two,
.heading-three,
.heading-four {
  @extend %bold;
}

// Follows the minor-third type scale, with line-height landing on the closest 4px grid
$typography: (
  "h1": (
    "font-size": pxToRem(48px),
    "line-height": pxToRem(60px),
  ),
  "h2": (
    "font-size": pxToRem(39px),
    "line-height": (
      48px,
    ),
  ),
  "h3": (
    "font-size": pxToRem(31px),
    "line-height": pxToRem(40px),
  ),
  "h4": (
    "font-size": pxToRem(25px),
    "line-height": pxToRem(36px),
  ),
  "subheading": (
    "font-size": pxToRem(20px),
    "line-height": pxToRem(28px),
  ),
  "body-large": (
    "font-size": pxToRem(18px),
    "line-height": pxToRem(28px),
  ),
  "body-regular": (
    "font-size": pxToRem(16px),
    "line-height": pxToRem(24px),
  ),
  "caption": (
    "font-size": pxToRem(14px),
    "line-height": pxToRem(20px),
  ),
);

.heading-one {
  font-size: getFontStyle(h1, font-size);
  line-height: getFontStyle(h1, line-height);
  letter-spacing: map-deep-get($letter-spacing, "negative", "large");
}

.heading-two {
  font-size: getFontStyle(h2, font-size);
  line-height: getFontStyle(h2, line-height);
  letter-spacing: map-deep-get($letter-spacing, "negative", "medium");
}

.heading-three {
  font-size: getFontStyle(h3, font-size);
  line-height: getFontStyle(h3, line-height);
  letter-spacing: map-deep-get($letter-spacing, "negative", "small");
}

.heading-four {
  font-size: getFontStyle(h4, font-size);
  line-height: getFontStyle(h4, line-height);
  letter-spacing: map-deep-get($letter-spacing, "negative", "extra-small");
}

.subheading,
.buttonText,
.buttonText--small,
.overline {
  @extend %bold;
}

.subheading {
  font-size: getFontStyle(subheading, font-size);
  line-height: getFontStyle(subheading, line-height);
}

.bodyOne,
.bodyTwo,
.caption,
.overline {
  @extend %regular;
}

.bodyOne {
  font-size: getFontStyle(body-large, font-size);
  line-height: getFontStyle(body-large, line-height);
}

.bodyTwo,
.buttonText {
  font-size: getFontStyle(body-regular, font-size);
  line-height: getFontStyle(body-regular, line-height);
}

.caption,
.overline,
.buttonText--small {
  font-size: getFontStyle(caption, font-size);
  line-height: getFontStyle(caption, line-height);
}

.overline {
  letter-spacing: map-deep-get($letter-spacing, "positive", "caps");
  text-transform: uppercase;
}
