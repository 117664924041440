@import "../../../styles/config.scss";

.legend {
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: pxToRem(4px);
  box-shadow: getFromMap($shadow, "md");
  bottom: pxToRem(16px);
  display: flex;
  height: pxToRem(48px);
  position: absolute;
  left: pxToRem(16px);
  list-style: none;
  padding: 0 pxToRem(16px);

  &_item {
    align-items: center;
    color: getColor($neutral, 800);
    display: flex;
    padding: pxToRem(4px) 0;

    & + & {
      margin-left: pxToRem(12px);
    }
  }

  &_square {
    align-items: center;
    background-color: red;
    height: pxToRem(16px);
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: pxToRem(16px);
    margin-right: pxToRem(12px);

    &.teal {
      background-color: getColor($teal, 500);
    }

    &.orange {
      background-color: getColor($orange, 500);
    }

    &.purple {
      background-color: getColor($purple, 500);
    }

    &.red {
      background-color: getColor($red, 500);
    }

    &.softGray {
      background-color: getColor($softGray, 500);
    }

    &.green {
      background-color: rgba(155, 215, 50, 1);
    }
  }
}
