@import "../../../styles/config.scss";

@keyframes animate-in {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.dropdown {
  animation-name: animate-in;
  animation-timing-function: ease-in-out;
  animation-duration: 0.25s;
  border-radius: pxToRem(8px);
  width: calc(100vw - 1.5rem);
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  transform-origin: right top;

  @media screen and (min-width: 450px) {
    max-width: pxToRem(320px);
  }
}