@import "../../../../styles/config.scss";

.bar {
  align-items: center;
  background-color: getColor($neutral, 0);
  border-radius: pxToRem(4px);
  box-shadow: getFromMap($shadow, "md");
  display: flex;
  flex-direction: column;
  padding: 0;
  z-index: 100;

  &_container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-shrink: 0;
    justify-content: center;
  }

  &_button_list {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &_scroll_indicator {
    flex-shrink: 0;
    padding: pxToRem(8px) 0;
    transition: opacity $hoverTransition;

    & svg {
      color: getColor($neutral, 400);
    }

    &:first-child {
      margin-top: pxToRem(12px);
    }

    @media screen and (min-width: getFromMap($mediaQueries, "desktop")),
      (min-height: 700px) {
      display: none;
      visibility: hidden;
    }

    &_disabled {
      opacity: 0.2;
    }
  }

  &_button_rows {
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    overflow-x: auto;

    & > button:first-of-type {
      margin-top: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-height: 549px) {
      max-height: pxToRem(96px);
    }

    @media screen and (min-height: 550px) {
      max-height: pxToRem(144px);
    }

    @media screen and (min-height: 700px) {
      max-height: initial;
    }

    @media screen and (min-width: getFromMap($mediaQueries, "tablet")) and (max-height: 10rem) {
      max-height: pxToRem(144px);
    }

    @media screen and (min-width: getFromMap($mediaQueries, "desktop")) {
      max-height: initial;
    }

    &.prevent_scroll {
      overflow-y: hidden;
    }
  }

  &_button {
    align-items: center;
    background: none;
    border-radius: pxToRem(4px);
    cursor: pointer;
    display: flex;
    height: pxToRem(48px);
    justify-content: center;
    outline: none;

    & svg {
      color: getColor($neutral, 400);
    }

    & img {
      height: pxToRem(20px);
      width: pxToRem(20px);
    }

    &.active {
      color: getColor($purple, 900);
    }

    &_drilldown {
      display: none;

      @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
        display: flex;
        margin-top: 0;
        &:hover:not(:disabled),
        &_active {
          & svg {
            fill: getColor($neutral, 800);
          }
        }
        &:disabled:not(&_loading) {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      @media (min-width: getFromMap($mediaQueries, "desktop")) {
        // margin-top: pxToRem(8px);
      }
    }

    &_home {
      color: getColor($purple, 500);
      opacity: 0.4;

      &:hover,
      &:focus,
      &.active {
        opacity: 1;
      }

      &.active > div {
        background-color: getColor($purple, 800);
      }
    }

    &_teal {
      color: getColor($teal, 500);
      &:hover:not(.active),
      &:focus {
        & svg {
          fill: getColor($teal, 500);
        }
      }

      &.active > div {
        background-color: getColor($teal, 500);
        & svg {
          fill: getColor($neutral, 0);
        }
      }
    }

    &_purple {
      color: getColor($purple, 500);
      &:hover:not(.active),
      &:focus {
        & svg {
          fill: getColor($purple, 500);
        }
      }

      &.active > div {
        background-color: getColor($purple, 500);
        & svg {
          fill: getColor($neutral, 0);
        }
      }
    }

    &_orange {
      color: getColor($orange, 500);
      &:hover:not(.active),
      &:focus {
        & svg {
          fill: getColor($orange, 500);
        }
      }

      &.active > div {
        background-color: getColor($orange, 500);
        & svg {
          fill: getColor($neutral, 0);
        }
      }
    }

    &_red {
      color: getColor($red, 500);
      &:hover:not(.active),
      &:focus {
        & svg {
          fill: getColor($red, 500);
        }
      }

      &.active > div {
        background-color: getColor($red, 500);
        & svg {
          fill: getColor($neutral, 0);
        }
      }
    }

    &_green {
      color: rgba(155, 215, 50, 1);
      &:hover:not(.active),
      &:focus {
        & svg {
          fill: rgba(155, 215, 50, 1);
        }
      }

      &.active > div {
        background-color: rgba(155, 215, 50, 1);
        & svg {
          fill: getColor($neutral, 0);
        }
      }
    }

    &_gray {
      color: getColor($softGray, 500);
      &:hover:not(.active),
      &:focus {
        & svg {
          fill: getColor($softGray, 500);
        }
      }

      &.active > div {
        background-color: getColor($softGray, 500);
        & svg {
          fill: getColor($neutral, 0);
        }
      }
    }
  }
}
