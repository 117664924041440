@import-normalize;
@import "./gt-walsheim-font";
@import "./noto_sans_jp";
@import "./body";

:root {
  --toastify-color-success: #2a8658;
  --toastify-color-info: #00838f;
  --toastify-color-error: #de002e;
  --font-family: "GT-Walsheim Pro", Arial, Helvetica, sans-serif "";
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
}

.Toastify__toast-body {
  font-family: var(--font-family);
  font-weight: 300;
}
