@import "../../../../styles/config.scss";

.error {
  @extend .caption;
  align-items: center;
  display: flex;
  color: getColor($red, 500);
  margin-top: pxToRem(8px);
}

.icon {
  height: pxToRem(16px);
  padding-right: pxToRem(8px);
  width: pxToRem(16px);
}
