@import "../../styles/config.scss";

.selector {
  bottom: 0;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
  pointer-events: none;
  position: absolute;

  &_wrapper {
    margin-left: auto;
    position: absolute;
    bottom: pxToRem(16px);
    right: pxToRem(16px);
    pointer-events: all;
  }

  &_button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: pxToRem(4px);
    transition: opacity $hoverTransition;

    &:focus {
      @include focusDropshadow;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &_language {
    align-items: center;
    color: getColor($neutral, 0);
    display: flex;
  }

  &_label {
    color: inherit;
    margin-left: pxToRem(8px);
  }

  &_row {
    align-items: center;
    background: none;
    border: none;
    border-radius: pxToRem(4px);
    cursor: pointer;
    color: getColor($neutral, 800);
    display: flex;
    padding: pxToRem(8px);
    width: 100%;

    & + & {
      margin-top: pxToRem(8px);
    }

    &:hover,
    &_active {
      color: getColor($purple, 500);
    }

    &:focus {
      color: getColor($purple, 500);
    }
  }

  &_icon {
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 24px;
    border: 1px solid getColor($softGray, 200);
    height: pxToRem(24px);
    width: pxToRem(24px);

    &_en {
      background-image: url("../../assets/flags/gb.svg");
    }

    &_ja {
      background-image: url("../../assets/flags/jp.svg");
    }
  }

  &_toggle {
    padding: pxToRem(8px);
  }

  &_dropdown {
    border-radius: pxToRem(4px);
    transform-origin: bottom right;
    padding: pxToRem(8px);
    position: absolute;
    top: initial;
    bottom: calc(100% + 1rem);
    z-index: 10000;

    @media (min-width: getFromMap($mediaQueries, "tablet")) {
      max-width: pxToRem(200px);
    }
  }

  &_languages {
    display: flex;
    flex-direction: column;
    position: absolute;
  }

  &_check {
    height: pxToRem(16px);
    width: pxToRem(16px);
    margin-left: auto;
  }
}
