@import "../../../../styles/config.scss";

.group {
  position: relative;
  width: 100%;

  & input {
    order: 2;
  }

  & > [data-position="left"] {
    order: 1;
  }

  & > [data-position="right"] {
    order: 3;
  }
}

.hasAddon {
  display: flex;
}
