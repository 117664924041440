// convert pixels to rems
@function pxToRem($value) {
  @return $value / 16px * 1rem;
}

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
      @if not map-has-key($map, $key) {
        @error "#{$key} was not found in #{$map}."
      }
        $map: map-get($map, $key);
    }
    @return $map;
}

@function getFromMap($map, $key) {
  @if not map-has-key($map, $key) {
    @error "#{$key} was not found in #{$map}";
  }
  @return map-get($map, $key);
}

// Returns the color from the color map defined in _colors.scss
@function getColor($color, $key) {
  @if not map-has-key($color, $key) {
    @warn "Color `#{$key}` was not found in `#{$color}`";
  }
  @return map-get($color, $key);
}

// Returns the font styles from the typography map defined in _typography.scss
@function getFontStyle($key, $style) {
  @if not map-has-key($typography, $key) {
    @warn "Size `#{$key}` was not found.";
  }
  @return map-deep-get($typography, $key, $style);
}

