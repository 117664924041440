@import "../../../styles/config.scss";

.loader_container {
  align-items: center;
  background-color: #f1f1f4;
  display: flex;
  flex: 1;
  height: calc(100vh - 3.5rem);
  justify-content: center;
  position: relative;
}

.spinner_container {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &_message {
    color: getColor($purple, 500);
    margin-top: pxToRem(12px);
  }
}

@keyframes fade-in-content {
  0% {
    visbility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

.main_content {
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: 0;
  width: 100%;

  &.active {
    &:not(.disabled_transition) {
      animation-name: fade-in-content;
      animation-duration: 0.5s;
      animation-delay: 0.25s;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;
    }

    &.disabled_transition {
      opacity: 1;
    }
  }
}
