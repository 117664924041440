@import "../../../styles/config.scss";
.group {
  display: flex;
  justify-content: flex-start;

  // Default space between components
  &.spacingLg {
    & > :not(style) ~ :not(style) {
      margin-left: getFromMap($spacing, 16);
    }
  }

  &.spacingXl {
    & > :not(style) ~ :not(style) {
      margin-left: getFromMap($spacing, 24);
    }
  }

  &.spacingMd {
    & > :not(style) ~ :not(style) {
      margin-left: getFromMap($spacing, 12);
    }
  }

  &.spacingSm {
    & > :not(style) ~ :not(style) {
      margin-left: getFromMap($spacing, 8);
    }
  }

  &.justifyBetween {
    justify-content: space-between;
    & > :not(style) ~ :not(style) {
      margin-left: 0;
    }
  }

  &.justifyStart {
    justify-content: flex-start;
  }

  &.justifyCenter {
    justify-content: center;
  }

  &.justifyEnd {
    justify-content: flex-end;
  }

  &.attached {
    & > :not(style) ~ :not(style) {
      margin-left: 0;
    }
  }
}
