@import "../../../../styles/config.scss";

.label {
  @extend .bodyTwo;
  color: getColor($neutral, 600);
  display: inline-block;
  padding-bottom: pxToRem(8px);
}

.xs {
  @extend .caption;
}

.required {
  color: getColor($red, 500);
  padding-left: pxToRem(6px);
}
