@import "../../../styles/config.scss";

.bodyLarge {
  @extend .bodyOne;
}

.bodyRegular {
  @extend .bodyTwo;
}

.caption {
  @extend .caption;
}

.h1 {
  @extend .heading-one;
}

.h2 {
  @extend .heading-two;
}

.h3 {
  @extend .heading-three;
}

.h4 {
  @extend .heading-four;
}

.subheading {
  @extend .subheading;
}

.overline {
  @extend .overline;
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 300;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.strikethrough {
  text-decoration: line-through;
}

.none {
  text-decoration: none;
}

.alignInherit {
  text-align: inherit;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.alignJustify {
  text-align: justify;
}

.purple {
  color: getColor($purple, 500);
}

.black {
  color: getColor($neutral, 0);
}
