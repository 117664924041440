@import "../../styles/config.scss";

.controls {
  bottom: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: pxToRem(56px);
}

.wrapper {
  height: 100%;
  padding: pxToRem(4px);
  position: sticky;
  pointer-events: none;
  z-index: 1000;
}
