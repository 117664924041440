@import "../../styles/config.scss";

@keyframes slide-in {
  0% {
    transform: translateX(pxToRem(320px));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(pxToRem(320px));
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.drawer_modal {
  z-index: 3000;
  @media (min-width: getFromMap($mediaQueries, "tablet")) {
    animation-timing-function: ease-in-out;
    animation-duration: 0.25s;

    &.open {
      animation-name: fade-in;
      animation-fill-mode: forwards;
    }

    &.closed {
      animation-name: fade-out;
      animation-fill-mode: forwards;
    }
  }
}
