@import "../../styles/config.scss";

.graph {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
  position: relative;
  scrollbar-width: thin;
  scrollbar-gutter: auto;
  z-index: 100;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: getColor($neutral, 0);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: getColor($neutral, 200);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: getColor($neutral, 400);
  }
}
