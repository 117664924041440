// Neutral
$neutral: (
  0: #FFF,
  50: #F2F2F2,
  100: #E6E6E6,
  200: #CCC,
  400: #999,
  600: #666,
  800: #333,
  1000: #000
);

// Purple
$purple: (
  50: #F1ECF8,
  100: #E3D9F2,
  200: #C2ACE2,
  300: #9974CE,
  400: #693CA9,
  500: #4F2D7F,
  600: #3F2466,
  700: #341D53,
  800: #231438,
  900: #170D26
);

// Green
$green: (
  50: #E9FBF2,
  100: #C5EDD8,
  200: #94D1B3,
  300: #6CB691,
  400: #469C71,
  500: #2A8658,
  600: #0F703F,
  700: #004522,
  800: #002F18,
  900: #002412,
);

// Orange
$orange: (
  50: #FEEFE7,
  100: #FCDFCF,
  200: #FBCEB7,
  300: #F8AE87,
  400: #F58E56,
  500: #F26B23,
  600: #D9540D,
  700: #A9410A,
  800: #481C04,
  900: #270F02,
);

// Red
  $red: (
  50: #FFE5EB,
  100: #FFB2C2,
  200: #FF809A,
  300: #FF4C71,
  400: #FF1949,
  500: #DE002E,
  600: #B20025,
  700: #80001A,
  800: #4C0010,
  900: #190005,
  );

// Teal
$teal: (
  50: #EDF6F7,
  100: #CCE6E8,
  200: #99CDD2,
  300: #66B5BC,
  400: #339CA6,
  500: #00838F,
  600: #0D6E77,
  700: #08575E,
  800: #0E494E,
  900: #0A2629,
);

// Soft Gray
$softGray: (
  50: #F8F7F6,
  100: #F5F3F2,
  200: #EAE7E4,
  300: #DFDBD6,
  400: #D6D0CA,
  500: #CBC4BC,
);