@import "../../../styles/config.scss";

.tooltip {
  background-color: getColor($purple, 900);
  border-radius: pxToRem(4px);
  display: flex;
  white-space: nowrap;
  color: getColor($neutral, 0);
  padding: pxToRem(8px) pxToRem(12px);
  z-index: 1;
}

.popper {
  z-index: 100;
}
