%regular {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
}

%bold {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
}

%bold {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
}

/*
 * This class adds a pseudo ::before element to a component, which
 * highlights with a branded purple border on focus.
 * Simply write '@extend %focus-outline' to use it.
*/

%focus-outline {
  position: relative;

  &::before {
    bottom: -0.2rem;
    box-shadow: 0 0 0 -2px #4f2d7f;
    content: "";
    display: block;
    left: -0.2rem;
    pointer-events: none;
    position: absolute;
    right: -0.2rem;
    top: -0.2rem;
    transition: all 0.15s ease;
    z-index: 1;
  }

  &:focus::before {
    box-shadow: 0 0 0 2px #4f2d7f;
  }
}
