@import "../../styles/config.scss";

.section {
  background-color: #f1f1f4;
  flex: 1;
  margin: 0 auto;
  overflow-y: auto;
  padding: pxToRem(32px) 0;
  scrollbar-width: thin;
  width: 100%;

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    padding: pxToRem(40px) pxToRem(16px);
  }

  @media screen and (min-width: getFromMap($mediaQueries, "laptop")) {
    padding: pxToRem(48px) pxToRem(16px);
  }
}

.container {
  margin: 0 auto;
  max-width: $maxWidth;
  width: 100%;
}
