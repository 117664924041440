@import "../../../styles/config.scss";

.button {
  align-items: center;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  transition: border-color $hoverTransition, box-shadow $hoverTransition;

  &:focus {
    @include focusDropshadow;
  }

  &.sm {
    height: pxToRem(32px);
    width: pxToRem(32px);

    & svg {
      height: pxToRem(12px);
      width: pxToRem(12px);
    }
  }

  &.md {
    height: pxToRem(40px);
    width: pxToRem(40px);

    & svg {
      height: pxToRem(16px);
      width: pxToRem(16px);
    }
  }

  &.lg {
    height: pxToRem(48px);
    width: pxToRem(48px);

    & svg {
      height: pxToRem(20px);
      width: pxToRem(20px);
    }
  }

  & svg {
    height: 100%;
    width: 100%;
  }

  &:disabled {
    pointer-events: none;
  }
}

.filled {
  background-color: getColor($purple, 500);
  color: getColor($neutral, 0);

  &:hover {
    background-color: getColor($purple, 400);
  }

  &:active {
    background-color: getColor($purple, 600);
  }

  &:disabled,
  &:hover:disabled {
    background-color: getColor($neutral, 200);
    color: getColor($neutral, 400);
  }

  &.danger {
    background-color: getColor($red, 500);
    color: getColor($neutral, 0);

    &:hover {
      background-color: getColor($red, 600);
    }

    &:active {
      background-color: getColor($red, 700);
    }

    &:disabled,
    &:hover:disabled {
      background-color: getColor($neutral, 200);
      color: getColor($neutral, 400);
    }
  }
}

.outline {
  background-color: getColor($neutral, 0);
  border: 1px solid getColor($purple, 500);

  &:hover {
    background-color: getColor($purple, 100);
  }

  &:active {
    background-color: getColor($purple, 200);
  }

  &:disabled,
  &:hover:disabled {
    background-color: getColor($neutral, 0);
    border-color: getColor($neutral, 200);
    color: getColor($neutral, 200);
  }
}

.outline,
.ghost,
.white {
  color: getColor($purple, 500);
}

.ghost {
  background-color: transparent;

  &:hover {
    background-color: getColor($purple, 100);
  }

  &:active {
    background-color: getColor($purple, 200);
  }

  &.danger {
    color: getColor($red, 500);

    &:hover {
      background-color: getColor($red, 50);
      color: getColor($red, 600);
    }

    &:active {
      background-color: getColor($red, 100);
    }
  }

  &:disabled,
  &:hover:disabled {
    background-color: transparent;
    color: getColor($neutral, 400);
  }
}

.white {
  background-color: getColor($neutral, 0);
  border: 1px solid transparent;

  &:hover,
  &:active {
    background-color: transparent;
    border: 1px solid getColor($neutral, 0);
    color: getColor($neutral, 0);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:disabled,
  &:hover:disabled {
    background-color: getColor($neutral, 0);
    color: getColor($purple, 500);
    opacity: 0.5;
  }
}
