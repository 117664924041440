// light
@font-face {
  font-family: 'GT-Walsheim Pro';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/GT-Walsheim-Pro-Light.otf');
}

@font-face {
  font-family: 'GT-Walsheim Pro';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/GT-Walsheim-Pro-Light-Oblique.otf');
}

// regular 
@font-face {
  font-family: 'GT-Walsheim Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/GT-Walsheim-Pro-Regular.otf');
}

@font-face {
  font-family: 'GT-Walsheim Pro';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/GT-Walsheim-Pro-Regular-Oblique.otf');
}

// medium
@font-face {
  font-family: 'GT-Walsheim Pro';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/GT-Walsheim-Pro-Medium.otf');
}

@font-face {
  font-family: 'GT-Walsheim Pro';
  font-weight: 500;
  font-style: italic;
  src: url('../fonts/GT-Walsheim-Pro-Medium-Oblique.otf');
}

// bold
@font-face {
  font-family: 'GT-Walsheim Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/GT-Walsheim-Pro-Bold.otf');
}

@font-face {
  font-family: 'GT-Walsheim Pro';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/GT-Walsheim-Pro-Bold.otf');
}