@import "../../../styles/config.scss";

.body {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  width: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: getColor($neutral, 0);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: getColor($neutral, 200);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: getColor($neutral, 400);
  }

  &_wrapper {
    height: 100%;
    margin: 0 auto;
    max-width: pxToRem(824px);
  }

  &_ranking {
    display: flex;
    flex-direction: column;

    &_end {
      align-items: flex-end;
    }

    &_overline {
      color: getColor($neutral, 600);
      margin-bottom: pxToRem(4px);
    }

    &_heading {
      color: getColor($neutral, 800);
    }

    &_group {
      display: flex;
      justify-content: space-between;
      padding: pxToRem(12px) pxToRem(16px) pxToRem(24px);

      @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
        padding: pxToRem(24px) pxToRem(24px);
      }
    }
  }
}

.intro {
  color: getColor($neutral, 800);
  margin: 0 pxToRem(16px);

  & p + p {
    margin-top: pxToRem(12px);
  }

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    margin: 0 pxToRem(24px);
  }
}

.cards {
  display: flex;
  flex-direction: column;
  padding: pxToRem(24px) 0;

  &_title {
    border-top: 1px solid getColor($softGray, 200);
    color: getColor($neutral, 800);
    padding: pxToRem(24px) 0 pxToRem(12px);
    margin: 0 pxToRem(16px);
    z-index: -1;

    @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
      margin: 0 pxToRem(24px);
    }
  }

  &_card {
    background: getColor($neutral, 0);
    border: none;
    border-radius: pxToRem(8px);
    cursor: pointer;
    display: flex;
    padding: pxToRem(16px);
    position: relative;
    transition: box-shadow 0.25s ease-in-out, transform 0.25s ease-in-out,
      z-index 0.25s ease-in-out;
    z-index: 1;

    &:last-child {
      border-bottom: none;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        box-shadow: getFromMap($shadow, "xxl");
        position: relative;
        transform: translateY(-4px);
        z-index: 100;
      }
    }

    &:focus {
      box-shadow: getFromMap($shadow, "xxl");
      position: relative;
      transform: translateY(-4px);
      z-index: 100;
    }

    &_score {
      border: none;
      margin-top: pxToRem(4px);
      padding: 0;
      width: 100%;
    }

    @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
      padding: pxToRem(16px) pxToRem(24px);
    }
  }
}

.dimension {
  &_header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: pxToRem(12px);
    padding: pxToRem(12px) pxToRem(16px) pxToRem(24px);

    @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
      padding: pxToRem(24px) pxToRem(24px);
    }
  }

  &_badge {
    align-items: center;
    border-radius: pxToRem(4px);
    color: getColor($neutral, 0);
    display: flex;
    flex: 1 0 auto;
    max-width: pxToRem(368px);
    padding: pxToRem(16px);
    width: 100%;

    & svg {
      color: getColor($neutral, 0);
    }

    &.teal {
      background-color: getColor($teal, 50);
      color: getColor($teal, 500);
    }

    &.orange {
      background-color: getColor($orange, 50);
      color: getColor($orange, 500);
    }

    &.purple {
      background-color: getColor($purple, 50);
      color: getColor($purple, 500);
    }

    &.red {
      background-color: getColor($red, 50);
      color: getColor($red, 500);
    }

    &.softGray {
      background-color: getColor($softGray, 50);
      color: hsla(32, 13%, 45%, 1);
    }

    &.green {
      background-color: hsl(82, 67%, 95%);
      color: hsl(82, 67%, 40%);
    }

    &_group {
      width: 100%;

      &_contents {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & + & {
          margin-top: pxToRem(4px);
        }
      }
    }
  }
}

.section_title {
  color: getColor($neutral, 800);
}

.suggestions {
  border-top: 1px solid getColor($softGray, 200);
  margin: pxToRem(16px) pxToRem(16px) 0;
  padding: pxToRem(16px) 0;

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    margin: pxToRem(24px) pxToRem(24px) 0;
    padding: pxToRem(24px) 0;
  }

  &_item {
    color: getColor($neutral, 800);
  }

  &_list {
    list-style: none;
    margin-top: pxToRem(12px);
  }

  &_icon {
    color: getColor($teal, 500);
  }

  &_item {
    display: flex;

    & + & {
      margin-top: pxToRem(16px);
    }

    & svg {
      flex-shrink: 0;
      height: pxToRem(12px);
      margin-top: pxToRem(6px);
      margin-right: pxToRem(12px);
      width: pxToRem(12px);

      &.teal {
        fill: getColor($teal, 500);
      }

      &.orange {
        fill: getColor($orange, 500);
      }

      &.purple {
        fill: getColor($purple, 500);
      }

      &.red {
        fill: getColor($red, 500);
      }

      &.softGray {
        fill: getColor($softGray, 500);
      }

      &.green {
        fill: rgba(155, 215, 50, 1);
      }
    }
  }

  &_alternate {
    color: getColor($neutral, 800);
    margin-top: pxToRem(8px);
  }
}

.icon {
  align-items: center;
  border-radius: pxToRem(16px);
  color: getColor($neutral, 0) !important;
  display: flex;
  flex-shrink: 0;
  height: pxToRem(32px);
  justify-content: center;
  margin-right: pxToRem(12px);
  width: pxToRem(32px);

  & svg {
    height: pxToRem(16px);
    width: pxToRem(16px);
  }

  &.teal {
    background-color: getColor($teal, 500);

    &.reversed {
      background-color: getColor($neutral, 0);
      color: getColor($teal, 500);
    }
  }

  &.orange {
    background-color: getColor($orange, 500);

    &.reversed {
      background-color: getColor($neutral, 0);
      color: getColor($orange, 500);
    }
  }

  &.purple {
    background-color: getColor($purple, 500);

    &.reversed {
      background-color: getColor($neutral, 0);
      color: getColor($purple, 500);
    }
  }

  &.red {
    background-color: getColor($red, 500);

    &.reversed {
      background-color: getColor($neutral, 0);
      color: getColor($red, 500);
    }
  }

  &.softGray {
    background-color: getColor($softGray, 500);

    &.reversed {
      background-color: getColor($neutral, 0);
      color: getColor($softGray, 500);
    }
  }

  &.green {
    background-color: rgba(155, 215, 50, 1);

    &.reversed {
      background-color: getColor($neutral, 0);
      color: rgba(155, 215, 50, 1);
    }
  }
}

.roadmap {
  padding: pxToRem(12px) pxToRem(16px) pxToRem(48px);

  @media screen and (min-width: getFromMap($mediaQueries, "tablet")) {
    padding: 0 pxToRem(24px) pxToRem(48px);
  }

  &_details {
    display: flex;
    position: relative;

    &_group {
      display: flex;
      flex-direction: column;
    }

    &.teal &_title,
    &.teal &_duration {
      color: getColor($teal, 500);
    }

    &.orange &_title,
    &.orange &_duration {
      color: getColor($orange, 500);
    }

    &.purple &_title,
    &.purple &_duration {
      color: getColor($purple, 500);
    }

    &.red &_title,
    &.red &_duration {
      color: getColor($red, 500);
    }

    &.softGray &_title,
    &.softGray &_duration {
      color: hsla(32, 13%, 45%, 1);
    }

    &.green &_title,
    &.green &_duration {
      color: hsl(82, 67%, 40%);
    }

    &_description {
      color: getColor($neutral, 800);
      padding-top: pxToRem(8px);
    }

    &.teal:before {
      background-color: getColor($teal, 500);
    }

    &.orange:before {
      background-color: getColor($orange, 500);
    }

    &.red:before {
      background-color: getColor($red, 500);
    }

    &.purple:before {
      background-color: getColor($purple, 500);
    }

    &.softGray:before {
      background-color: getColor($softGray, 500);
    }

    &.green:before {
      background-color: rgba(155, 215, 50, 1);
    }

    &:before {
      content: "";
      display: block;
      top: 0;
      left: pxToRem(15px);
      position: absolute;
      width: pxToRem(2px);
      height: 100%;
      z-index: -1;
    }

    &:last-child:before {
      height: pxToRem(32px);
    }

    &:first-of-type {
      margin-top: pxToRem(12px);
    }

    & + & {
      padding-top: pxToRem(32px);
    }
  }
}
